<template>
  <privacy-policy-container/>
</template>

<script>
  import PrivacyPolicyContainer from '@/entities/terms/features/privacy-policy/privacy-policy.container';

  export default {
    name: 'PrivacyPolicyView',
    components: { PrivacyPolicyContainer },
    props: {},

    data() {
      return {};
    },

    computed: {},

    methods: {},

  };
</script>

<style lang="scss" scoped>

</style>
