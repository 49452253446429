<template>
  <div class="sp-privacy-policy">
    <div v-html="htmlContent"></div>
  </div>
</template>

<script>
  import TermsApi from '@/entities/terms/api/terms.api';
  import normalizeAnchors from '@/entities/terms/helpers/normalize-anchors.helper';

  export default {
    name: 'PrivacyPolicyContainer',
    props: {},

    data() {
      return {
        htmlContent: '',
      };
    },

    computed: {},

    beforeMount() {
      this.loadCookiesTerms();
    },
    methods: {
      async loadCookiesTerms() {
        try {
          this.htmlContent = await TermsApi.getPrivacy();
          this.$nextTick(() => {
            normalizeAnchors('sp-privacy-policy');
          });
        } catch (err) {
          console.log('err', err);
        }
      },
    },

  };
</script>

<style lang="scss">
</style>
